@import "mixins";

.form-control {
  font-size: 1.4rem;
}

select {
  &.form-control {
    height: 48px;
  }
}

.DateInput_input {
  line-height: 18px;
}


.sz-btn {
  &.btn-primary-light{
  color: var(--btn-dark-text) !important;
  background: var(--primary-light) !important;
}
}