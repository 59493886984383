@import "src/mixins";

.detail-block1 {
    .status-sticker {
        margin-top: 34px;
    }

    .info-container {
        margin-top: 28px;
    }
}
.block1-header{
    .intervention-view-title{
        font-family: DINPro-Regular;
        font-size: 2rem;
        @include color(var(--primary));
    }
    .intervention-view-go-back{
        font-family: DINPro-Regular;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    .maps-icon{
        font-size: 1.6rem;
        color: var(--intervention-detail-address);
    }
}

.document-list {
    max-height: 400px;
    overflow-x: auto;
}

.sz-left-border::after {
    background: var(--toulouse-blue2);
}