.DateInput {
  width: 120px;

  &_input {
    padding: 10px 8px;
    font-size: 1.4rem;
  }
}


.DateRangePickerInput {
  height: 48px;

  &_calendarIcon {
    margin: 0;
    padding: 8px;
  }

  &_arrow_svg {
    height: 24px;
    width: 24px;
  }
}
