@import "mixins";

[class^='sz-icon-line'], [class*=' sz-icon-line'] {
  font-size: 2.4rem;
}

[class^='sz-icon-bold'], [class*=' sz-icon-bold'] {
  font-size: 2.4rem;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.sz-form-group {
  .sz-form-group__input {
    height: 48px;
    @include border(2px, var(--input-text-border));
  }
}

.btn {
  font-size: 1.4rem;
}

.sz-btn {
  height: 48px;
  border-radius: 2px;
  font-size: 1.4rem;
  @include color(var(--btn-text));
  @include background(var(--btn-text-bg));

  &-outline {
    height: 48px;
    @include border-color(var(--btn-outline-border));

    &:hover {
      @include background(var(--btn-outline-hover));
    }
  }

  &.sz-line-height-small {
    height: 36px;

    [class^='sz-icon-line'], [class*=' sz-icon-line'] {
      font-size: 1.1rem;
    }
  }
}

.sz-line-height-small {
  height: 36px;
}

.normal {
  font-size: 1.2rem;
}

.alert-warning .bg-danger {
  @include background(orange);
}

.list-table {
  th {
    &:before {
      float: right;
      top: 10px;
      font-family: 'sz-icon-line' !important;
      padding-right: 20px;
    }

    &:after {
      background: none !important;
    }

    &.sz-icon-line {
      font-size: 1.4rem;
      font-family: none !important;
      cursor: pointer;
    }

    background-color: var(--table-list-header-bg);
  }
}

.sz-header-stepper__step::after {
  background: var(--toulouse-pink);
}

.sz-underline::after {
  background: var(--toulouse-pink);
}

.custom-control {
  z-index: inherit;
}

.create-user-container .react-date-picker {
  height: 50px;
  width: 170px;
  @at-root #{&}__wrapper {
    &::before {
      content: '\f120';
      font-size: x-large;
      font-family: 'sz-icon-line';
    }
  }
}

.nav {
  &.nav-tabs {
    font-size: 1.6rem;

    .nav-link {
      background: none;
      font-family: "DINPro-Regular";

      &.active {
        font-family: "DINPro-Bold";
      }
    }
  }
}

.nav-link {
  padding-bottom: 20px;
}

.sz-btn-tertiary {
  border: none !important;
  box-shadow: none;
}

.sz-table {
  tr {
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 1.6rem;
      font-family: "DINPro-Regular";

      &:first-child {
        padding-left: 20px;
      }
    }

    th {
      font-size: 1.6rem;
      font-family: "DINPro-Medium";

      &:first-child {
        padding-left: 20px;
      }
    }
  }
}
