.leaflet-container {
  width: auto;
  height: 100%;
  min-height: 400px;
}

.my-map {
  height: 100%;
  min-height: 250px;

}
