@import "src/zindex";

.sz-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: map-get($zIndex, header);

  &.unlogged {
    padding-right: 20rem;
  }

  .title {
    justify-content: center;
    flex-grow: 2;
  }

  .group {
    display: flex;
    height: 60px;
    padding: 0 24px;
    text-align: center;
    align-items: center;

    + .group {
      border-left: 2px solid var(--toulouse-grey1);
    }

    &.no-border {
      border-left: 0;
    }
  }

  .login {
    height: 24px;
    width: 24px;
    background: url('../../../assets/icon/login.svg');
  }

  &-stepper__step span {
    font-size: 1.4rem;
  }
}

.sz-dropdown .dropdown-menu::after, .sz-dropdown .dropdown-menu::before {
  left: 90% !important;
}

.dropdown-menu {
  left: -60% !important;
  font-size: 1rem;
}
