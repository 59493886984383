@import "zindex";
@import "colors";
@import "mixins";
@import "sz";
@import "forms";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  background: var(--body-background);
}

html, body, #root {
  height: 100%;
}

#root {
  padding-top: 60px;
}

h1 {
  font-size: 2rem;
}

.small {
  font-size: 1rem;
}

.h-title {
  height: calc(100% - 10.5rem);
}

.box-shadow {
  background: var(--white);
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);
  padding: 8px 16px;
}

.ptn-5 {
  top: -5px;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

$statusList: (
    ena: "ena",
    pla: "pla",
    enc: "enc",
    ann: "ann",
    rea: "rea"
);

$statusListAsset: (
    projet: "ena",
    en-service: "rea",
    sorti: "pla"
);

.applicationSource
{
  &::after {
    background: #0b37eb;
  }
}

.sz-table tbody tr {
  td:first-child::after {
    opacity: 1;
  }
  td:first-child {
    position: relative;
  }
  @each $key, $value in $statusList {
    &.intervention-row-#{$value} {
      td:first-child::before {
        background: var(--status-#{$value});
        top: 3px;
        bottom: 3px;
        position: absolute;
        content: "";
        width: 4px;
        border-radius: 4px;
        position: absolute;
        left: 4px;
      }
    }
  }
  @each $key, $value in $statusListAsset {
    &.asset-enterre-row-#{$key},
    &.asset-visible-row-#{$key}
    {
      td:first-child::before {
        background: var(--status-#{$value});
        top: 3px;
        bottom: 3px;
        position: absolute;
        content: "";
        width: 4px;
        border-radius: 4px;
        position: absolute;
        left: 4px;
      }
    }
  }
}

.detail-block3 {
  .timeline-item {
    @each $key, $value in $statusList {
      &.timeline-#{$value} {
        .timeline-badge {
          background-color: var(--status-#{$value});
        }
      }
    }
  }
}
.status-sticker {
  width: 12px;
  height: 12px;
  padding: 0;
  background-color: #DDD;
  border-radius: 50%;
  display: inline-block;
  vertical-align: sub;

  @each $key, $value in $statusList {
    &.status-sticker-#{$key} {
      background-color: var(--status-#{$value});
    }
  }
}

abbr[title] {
  text-decoration: none;
}

.position-absolute-bottom {
  position: absolute;
  bottom: 0;
}

.no-scroll {
  overflow-y: hidden;
}
 .sz-aside{
   &.closed{
       width: 74px;
   }
 }

.list-table {
  height: 100%;
  div {
    width: 100%;
  }
  thead{
    tr {
      background: none !important;
    }
  }
  td {
    vertical-align: middle;
  }
  th {
    font-weight: bold !important;

    &:before {
      float: right;
      top: 10px;
      font-family: 'sz-icon-line' !important;
      padding-right: 20px;
      padding-left: 100px;
    }

    &:after {
      background: none !important;
    }

    &.sz-icon-line {
      font-size: 1rem;
      font-family: 'DINPro-Regular' !important;
      cursor: pointer;
    }
    font-family: 'DINPro-Regular' !important;
    background-color: var(--table-list-header-bg);
  }
  tr{
    cursor: pointer;
    color: var(--primary-dark) !important;
    &.selected-project{
      background: var(--light);
    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--gray-0);
  }
}
